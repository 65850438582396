$( document ).ready(function() {

    $('.galerie').magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery:{
            enabled:true
        }
    });



    // Slider

    var $next = $('#next');
    var $prev = $('#prev');

    var total = 24;
    var position = $('main').data('position');
    if (position==0) { position=total};
    var goTo = total - position;
    $currentSlider = $('.slick-slider');

    $currentSlider.on('init', function(event, slick, direction){
        // Workaround basierend auf https://github.com/kenwheeler/slick/issues/1802
        slideToPosition();
    });

    $currentSlider.on('afterChange', function(event, slick, direction){
        // Navigations-Pfeile ein und ausblenden (erster/letzter Slider)
        if($currentSlider.slick('slickCurrentSlide')==0) {
            //$('.prev').css('visibility','hidden');
            $('.prev').css('opacity','0.3').css('filter','alpha(opacity=30)');
        } else {
            //$('.prev').css('visibility','visible');
            $('.prev').css('opacity','1').css('filter','alpha(opacity=100)');
        }
        if($currentSlider.slick('slickCurrentSlide')==(total-1)) {
            //$('.next').css('visibility','hidden');
            $('.next').css('opacity','0.3').css('filter','alpha(opacity=30)');
        } else {
            //$('.next').css('visibility','visible');
            $('.next').css('opacity','1').css('filter','alpha(opacity=100)');
        }

    });

    $currentSlider.slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
        prevArrow:$prev,
        nextArrow:$next,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });




    function slideToPosition() {
        try {
            $currentSlider.slick('slickGoTo', goTo)
        }
        catch(error) {
            setTimeout(slideToPosition, 100);
            return;
        }

        //doSomethingWhenReady();

    }



});



